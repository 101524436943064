@import url('https://fonts.googleapis.com/css?family=Arsenal:400,700');
$primary: #05abe0; /* MAIN COLOR */
$secondary: #007999; /* SECONDARY COLOR */
$blk: #333; /* DARK GRAY, REASON WHY IT'S SET TO #333 IS SO YOU CAN DARKEN IT IF NEEDED USING darken($blk, 10%); FOR EXAMPLE. YOU COULD ALSO USE 2 VARIABLES INSTEAD */
$wht: #fafafa;
$border-radius: 0px; /* USE THIS TO SET THE BORDER RADIUS FOR BUTTONS */
$footerLinks: #fafafa;

.flash {
	display:none;
}
body {
	font-family: 'Arsenal', sans-serif;
	background: #87e0fd; /* Old browsers */
	background: -moz-linear-gradient(top,  #87e0fd 0%, #53cbf1 51%, #05abe0 100%); /* FF3.6-15 */
	background: -webkit-linear-gradient(top,  #87e0fd 0%,#53cbf1 51%,#05abe0 100%); /* Chrome10-25,Safari5.1-6 */
	background: linear-gradient(to bottom,  #87e0fd 0%,#53cbf1 51%,#05abe0 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#87e0fd', endColorstr='#05abe0',GradientType=0 ); /* IE6-9 */
}
.top-pad {
	color: darken($blk, 25%);
	font-size: 1.3em;
}
.main-nav, .main {
  position: relative;
}
.main-nav {
  background: #fff;
  // height: 100px;
  margin-bottom: -80px;
  box-shadow: 0 0px 20px rgba(0,0,0,.4);
}
.main-nav-scrolled {
  position: fixed;
  width: 100%;
  top: 0;
}
.main {
  background: #f2f2e8;
  padding: 110px 50px 50px;
	border-radius: 0;
  column-count: 2;
  column-gap: 40px;
}

nav {
	z-index: 1000;
	// background: transparent;
}
.navbar-default {
	background: rgba(135,224,253,.9);
	border:0;
}
.is-sticky  {
	background-color: red;
}
.navbar .navbar-nav {
    > li > a {
		text-align: center;
		height: 100px;
		display: flex;
		align-items: center;
		color: $wht;



		@media (max-width: 767px) {
		    height:22px;
		    display: inline-block;
		}

	    &:hover, &:focus {
	    	background: $primary;
	    	color: lighten($primary, 50%);
	    }
	}
}

.navbar-right {
	margin-top: 0px;
		@media(max-width: 767px){
			background: $primary;
		}
}

.navbar-toggle {
    margin: 30px 15px 8px 0px;
    border: 1px solid transparent;
    border-radius: 4px;

	@media (max-width: 388px) {
		margin-top: 14px;
	}
}


/** LOGIN FORM **/

@mixin btn1 {
	text-align: center;
	margin: 0 auto;
	border: 1px solid $primary;
	border-radius: $border-radius;
	background: $primary;
	color: $wht;
	padding: 0.5em 2em;

	&:hover {
		background: lighten($primary,10%);
		color: lighten($wht, 10%);
		box-shadow: 0px 0px 3px lighten($blk,10%);
	}
}

@mixin btn2 {
	@include btn1;
	background: darken($wht, 5%);
	color: $primary;
	border: none;

	&:hover {
		background: $wht;
		color: $primary;
		border: none;
	}
}

.modal-dialog {
	max-width: 300px;
	text-align: center;
	margin: 6em auto;

	.close {display: none;}

	.modal-content {
		color: $primary;

		h2 {
			text-align:center;
		}
	}

	input {
		border: none;
		border-bottom: 1px solid darken($primary,20%);
		text-align: center;
	}

	button {
		background: transparent;
		color: $wht;
		display: block;
		border:none;
		padding: 1em 2em;
		margin: 0 auto;

		&:hover {
			background: transparent;
			color: #fff;
			box-shadow: none;
			text-shadow: 0px 0px 3px $blk;
			border:none;
		}
	}

	input[type="submit"] {
		@include btn1;
		display: block;
		width: 75%;
	}

}

.modal-header, .modal-footer {
	background: $primary;
	color: $wht;
}

input#username {
	margin-bottom: 20px;
}

/** END LOGIN FORM **/
// start sections
section {
	background-repeat: no-repeat;
	background-size: cover;
	background-position: center;
	background-attachment: fixed;
	@media(max-width:1024px){
		background-attachment: scroll;
	}
}
.section-a {
	background-image: url('../img/bg1.jpg');
	padding: 250px 0;
	color: $wht;
	text-align: center;
	h1{
		font-size: 3em;
	}
	p{
		font-size: 2em;
		@media(max-width: 767px){
			font-size: 1.5em;
		}
	}
	@media(max-width: 767px){
		padding: 100px 0;
	}
	// margin-top: 100px;
}
.section-b {
	padding-bottom:  100px;

}
.section-c {
	background-image: url('../img/bg2.jpg');
	padding: 250px 0;
	color: $blk;
	text-align: center;
	h1{
		font-size: 3em;
	}
	p{
		font-size: 2em;
		@media(max-width: 767px){
			font-size: 1.5em;
		}
	}
	.cta-box {
		margin-top: 60px;
		a {
			padding: 25px 35px;
			font-size: 1.4em;
			color: $wht;
			text-decoration: none;
			background:$primary;
			&:hover{
				background: $wht;
				color: $primary;
			}
		}
	}
	@media(max-width: 767px){
		padding: 100px 0;
	}
}
// end sections

footer {
	padding: 30px 0px 20px;
	// background: $primary;
	border-top: 1px solid $wht;
	color: $footerLinks;

	a {
		color: $footerLinks;

		&:hover {
			color: lighten($footerLinks, 10%);
		}
	}
}

input#username, input#password {
	width: 100%;
}

@media (max-width: 1024) {
	input#username, input#password {
  		font-size: 16px;
	}
}

select {
    -moz-appearance: none;
    text-indent: 0.01px;
    text-overflow: '';
}
